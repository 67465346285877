import React from 'react';
import * as S from "./PageStyle";

const PrivacyPage = () => {
    return (
        <S.Wrapper>
            <S.Head>
                PRIVACY POLICY
            </S.Head>
            <S.Para>
                Paper Free solution is committed ensure privacy and protection of your information. To endow you with our uninterrupted use of services, we collect very limited personal information about you, and the collected data wouldn’t be disclosed or sold. To enhance better protection of your privacy we provide this notice explaining our information practices and the choices you can make about the way your information is collected and used.
            </S.Para>
            <S.SubHead>
                OVERVIEW
            </S.SubHead>
            <S.Para>
                We commit to respecting your online data privacy. We further recognize your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us. Information that is considered personal about you by us includes, but is not limited to, your name, address, email address or other contact information.
            </S.Para>
            <S.Para>
                To use this website/app, you would be required to register yourself by providing the following information which includes, but is not limited to:
            </S.Para>
            <S.Para>
                <ul>
                    <li>
                        Name
                    </li>
                    <li>
                        Mobile Number
                    </li>
                    <li>
                        Address
                    </li>
                    <li>
                        Email Address
                    </li>
                    <li>
                        IP Address
                    </li>
                </ul>
            </S.Para>

            <S.SubHead>
                NOTIFICATION OF MODIFICATIONS AND CHANGES TO THE T&C AND PRIVACY POLICY
            </S.SubHead>

            <S.Para>
                We reserve the right to change the Terms and Privacy Policy from time to time as seen fit, without any public intimation, and your continued use of the site or App will signify your acceptance of any amendment to these terms.
            </S.Para>

            <S.Para>
            You are therefore advised to re-read the Terms of Service and Privacy Policy on a regular basis. Should it be that you do not accept any of the modifications or amendments to the Terms, you may terminate your use of this website and app on mobile devices immediately.
            </S.Para>

            <S.SubHead>
                INFORMATION WE COLLECT
            </S.SubHead>

            <S.Para>
            Our website/app’s online registration form requires users to give us contact information (like your name, address, mobile number, and email address), and demographic information (like your zip code/pin code). As a member you are required to provide a valid email address at registration and choose a username or alias that represents your identity on our website and app on mobile devices.
            </S.Para>

            <S.SubHead>
                INFORMATION USAGE
            </S.SubHead>

            <S.Para>
                Your contact information is used to contact you when necessary. We use your IP address to help diagnose problems with our server, and to administrator our website. Your IP address is also used to help identify you and to gather broad demographic information. We may use your IP address to help protect our partners and ourselves from fraud.
            </S.Para>
            <S.Para>
                We may only release your personal information to a third-party in order comply with a Court Order or other similar legal procedure, or when we believe in good faith that such disclosure is necessary to comply with the law; prevent imminent physical harm or financial loss; or investigate or take action regarding illegal activities, suspected fraud, or violations of Our Terms of Use. We may disclose certain information about you, your recharge information, and data analytics to our business partners.
            </S.Para>
            <S.Para>
                By way of example (without limiting and foregoing), third parties may sometimes unlawfully intercept or access transmissions, or other parties may abuse or misuse your information that they collect from our Websites. Therefore, we do not promise, and you should not expect, that any information that we collect about you, or private communications would always remain private.
            </S.Para>

            <S.SubHead>
                ACCESSING, REVIEWING AND CHANGING YOUR PROFILE
            </S.SubHead>

            <S.Para>
                Following registration, you can review and change the information you submitted except your display name, during registration. If you change any information, we may keep track of your old information. You can change your registration information such as: name, address, city, state, zip code, country, phone number, and profile.
            </S.Para>

            <S.Para>
                To remove your profile so that others cannot view it, contact our customer support team at Paper Free solution - support@paperfreesolutions.com
            </S.Para>

            <S.Para>
                We will retain in our files the information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Further, such prior information is never completely removed from our databases due to technical and legal constraints, including stored 'back up' systems. Therefore, you should not expect that all your personally identifiable information will be completely removed from our databases in response to your requests.
            </S.Para>

            <S.SubHead>
                OTHER INFORMATION COLLECTORS
            </S.SubHead>

            <S.Para>
                Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our websites or App or on other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their own privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.
            </S.Para>

            <S.Para>
                If you have any questions or concerns regarding this privacy policy, you should contact us support@paperfreesolutions.com
            </S.Para>
            <S.Head />

        </S.Wrapper>

    )
}

export default PrivacyPage
