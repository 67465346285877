import React from 'react';
import * as S from "./PageStyle";


const ReturnPage = () => {
    return (
        <S.Wrapper>
            <S.Head>
                RETURNS AND REFUNDS POLICY
            </S.Head>

            <S.Para>
                <ul>
                    <li>
                        We do not refund any amounts once a subscription plan is purchased for usage of our application, irrespective of cancellation, discontinuation, or upgradations.
                    </li>
                    <li>
                        The services we provided are subscriptions which a user can purchase for a limited amount of time to use our software products (Check Pricing Plans).
                    </li>
                    <li>
                        We, Line Sale App, (Product of Paper free Solutions) do not take any responsibility on what subscription plans a user purchases, as all the information regarding the pricing plans, application usage and other operational features are already shown on our website along with customer care information.
                    </li>
                    <li>
                        The user is responsible for any liabilities that come with wrongly purchasing any plans from our website. There will be no refunds given to the user in case of cancellation, discontinuation, and/or change of plans whatsoever.
                    </li>
                    <li>
                        Kindly read all the policy documents before registration and/or purchase of any plans
                    </li>
                    <li>
                        If you have any questions about our Returns and Refunds Policy, please contact us by email: support@paperfreesolutions.com
                    </li>
                </ul>
            </S.Para>

            <S.Head />

        </S.Wrapper>
    )
}

export default ReturnPage
