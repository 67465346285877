import styled from "styled-components";

export const Image = styled.img`
  height: 80%;
  margin-right: ${(props) => (props.isRegister ? "60px" : "120px")};
  transition: margin 1s;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  /* position: relative; */
  height: calc(100vh - 155px);
  padding: 0 8vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: -1;
  @media only screen and (max-width: 600px) {
    height: calc(100vh - 90px - 80px);
    width: 100%;
  }
`;

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 10%;
  left: 5%;
  color: grey;
  

  display: flex;
  flex-direction: row;
  align-items: center;

`

export const FooterLinks = styled.a`
  text-decoration: none;
  color: grey;
  padding: 0 5px;
`