import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export const Head = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-top: 50px;
  font-weight: bold;
`;

export const SubHead = styled.h3`
  font-size: 1.2rem;
  width: 90%;
  text-align: left;
  padding-left: 5%;
  font-weight: bold;
  margin-top: 25px;
`;

export const Para = styled.p`
  font-size: 1rem;
  text-align: justify;
  width: 85%;
  margin: 0 auto;
  margin-top: 25px;
`;



