import * as S from "./OtpModalStyled";
import React from "react";
import ReactDom from "react-dom";
import * as C from "../../common/common";
import API from "../../../utils/Api";


const OtpModal = (props) => {
    

    const sendOTP = () => {
        props.setOTP({sent: true});
        console.log(props.otp.number);
        API.post('/Client/ValidateClientMobileNo',
        {
            "mobileNumber" : props.otp.number
        })
        .then((res) => {
            if(res.data.statusCode === 102)
            {
                props.setServerMessage(res.data.statusDesc);
                props.setVerify({sent: true});
            }
        })
        .catch((err) => console.log(err))
    }

    const verifyOTP = () => {
        console.log(props.verify);
        API.post('/Client/ValidateClientCompany',
        {
            "mobileNumber": props.verify.number,
            "companyName" : props.verify.companyName,
            "otp" : props.verify.otp,
            "password": props.verify.password
        })
        .then((res) => {
            // props.setServerMessage(res.data.statusDesc || res.data.data.message );
            console.log(res);
            props.signUp();
        })
        .catch((err) => console.log(err))
        // props.setVerify({sent: true});
    }

    return ReactDom.createPortal(
        <S.Wrapper onClick={() => props.setIsOpen(false)} isOpen={props.modalIsOpen}>
            <S.Modal onClick={(e) => e.stopPropagation()}>
                {
                    props.verify.sent ?
                    <>
                    {props.serverMessage}
                    </>
                    :
                    <>
                    {
                        props.otp.sent ?
                        <>
                        <S.TitleText>Verify OTP</S.TitleText>
                    
                        <S.SubWrapper>
                            <S.SubHead>
                            Company Name<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter Company Name"
                            value={props.verify.companyName}
                            onChange={(e) => props.setVerify(data => ({ ...data, companyName: e.target.value }))}
                            />
                        </S.SubWrapper>

                        <S.SubWrapper>
                            <S.SubHead>
                            Contact Number<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter Contact Number"
                            value={props.verify.number}
                            onChange={(e) => props.setVerify(data => ({ ...data, number: e.target.value }))}
                            />
                        </S.SubWrapper>


                        <S.SubWrapper>
                            <S.SubHead>
                            OTP<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter OTP"
                            value={props.verify.otp}
                            onChange={(e) => props.setVerify(data => ({ ...data, otp: e.target.value }))}
                            />
                        </S.SubWrapper>

                        <S.SubWrapper>
                            <S.SubHead>
                            Password<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter the password"
                            value={props.verify.password}
                            onChange={(e) => props.setVerify(data => ({ ...data, password: e.target.value }))}
                            />
                        </S.SubWrapper>


                        <C.AuthButton type="button" onClick={verifyOTP}>
                            Verify
                        </C.AuthButton>
                        </>
                        :
                        <>
                        <S.TitleText>Send OTP</S.TitleText>
                    
                        <S.SubWrapper>
                            <S.SubHead>
                            Contact Number<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter Contact Number"
                            value={props.otp.number}
                            onChange={(e) => props.setOTP(data => ({ ...data, number: e.target.value }))}
                            />
                        </S.SubWrapper>


                        <C.AuthButton type="button" onClick={sendOTP}>
                            Send
                        </C.AuthButton>
                        </>
                    }
                    </>
                }
                    
            </S.Modal>
        </S.Wrapper>,
        document.querySelector("#modal")
    );
};

export default OtpModal;
