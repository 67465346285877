import * as S from "./SignInOTPStyled";
import React, { useState } from "react";
import ReactDom from "react-dom";
import * as C from "../../common/common";
import API from "../../../utils/Api";



const SignInOTP = (props) => {


    const verifyOTP = () => {
        console.log('verify')
        console.log(props.verify);

        props.setVerify(prev => ({...prev, modal: false}))

        API.post('/authentication/forgotpasswordupdate',
        {
            "mobileNumber" : props.verify.number,
            "otp": props.verify.otp,
            "password": props.verify.newPassword
        })
        .then(res => {
            console.log(res);
            props.setOTPState(prev => ({ ...prev, sent: true, statusCode: res.data.statusCode, message: res.data.statusDesc || res.data.data.message }));
        })
        .catch(err => console.log(err));
    }
    const forgotPassword = (number) => {
        props.setOTPState(prev => ({
            ...prev,
            sent: true,
        }));
        API.post('/authentication/forgotpassword',
        {
            "mobileNumber" : number
        })
        .then((res) => {
            console.log(res);
            props.setOTPState(prev => ({ ...prev, statusCode: res.data.statusCode, message: res.data.statusDesc || res.data.data.message }));
        })
        .catch((err) => console.log(err))
    }

    return ReactDom.createPortal(
        <S.Wrapper onClick={() => props.setIsOpen(false)} isOpen={props.modalIsOpen}>
            <S.Modal onClick={(e) => e.stopPropagation()}>
                {
                    props.otpState.sent || props.verify.sent ?
                    <S.TitleText>{props.otpState.message}</S.TitleText>
                    :
                    <>
                    {
                        props.verify.modal?
                        <>
                        <S.TitleText>Verify OTP</S.TitleText>

                        <S.SubWrapper>
                            <S.SubHead>
                            Phone Number<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter your Phone Number"
                            value={props.verify.number}
                            onChange={(e) => props.setVerify((prev) => ({...prev, number: e.target.value}))}
                            />
                        </S.SubWrapper>

                        <S.SubWrapper>
                            <S.SubHead>
                            OTP<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter OTP"
                            value={props.verify.otp}
                            onChange={(e) => props.setVerify((prev) => ({...prev, otp: e.target.value}))}
                            />
                        </S.SubWrapper>

                        <S.SubWrapper>
                            <S.SubHead>
                            New Password<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter New Password"
                            value={props.verify.newPassword}
                            onChange={(e) => props.setVerify((prev) => ({...prev, newPassword: e.target.value}))}
                            />
                        </S.SubWrapper>

                        <C.AuthButton type="button" onClick={verifyOTP}>
                            Verify
                        </C.AuthButton>
                        </>
                        :
                        <>
                        <S.TitleText>Send OTP</S.TitleText>

                        <S.SubWrapper>
                            <S.SubHead>
                            Phone Number<span>*</span>
                            </S.SubHead>
                            <S.InputField
                            placeholder="Enter your Phone Number"
                            value={props.number}
                            onChange={(e) => props.setNumber(e.target.value)}
                            />
                        </S.SubWrapper>

                        <C.AuthButton type="button" onClick={()=> forgotPassword(props.number)}>
                            Submit
                        </C.AuthButton>
                        </>
                    }
                    </>
                }
                    {
                        !props.verify.modal &&
                        <S.VerifyWrapper onClick={() => {
                                props.setOTPState({
                                    sent: false,
                                    statusCode: "",
                                    message: ""
                                });
                                props.setVerify({
                                    number: "",
                                    otp: "",
                                    newPassword: "",
                                    modal : true
                                })
                                
                            }}>
                                Verify OTP
                            </S.VerifyWrapper>
                    }

            </S.Modal>
        </S.Wrapper>,
        document.querySelector("#modal")
    );
};

export default SignInOTP;
