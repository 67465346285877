import React from 'react';
import * as S from './FooterStyled';

const Footer = ()=>{
    return (
        <S.Wrapper>
            <S.Text>Product of Paper Free Solutions | PETALS No. MBT 4-94 12, Platinum Jubilee Memorial Building, Shankarapura, Udupi, Karnataka-574115</S.Text>
        </S.Wrapper>
    );
}

export default Footer;