import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import * as S from "./ReportStyled";

function ResponsiveRow({ items, table }) {
  if (items.length === 5) {
    return (
      <div>
        <Container>
          <Col>
            <Row className="align-items-center" style={{ marginTop: 20 }}>
              <Col
                className="mt-2"
                xs={"auto"}
                sm={"auto"}
                md={"auto"}
                lg={"auto"}
                xl={"auto"}
              >
                {items[0]}
              </Col>
              <Col
                className="mt-2"
                xs={"auto"}
                sm={"auto"}
                md={"auto"}
                lg={"auto"}
                xl={"auto"}
              >
                {items[1]}
              </Col>
              <Col
                className="mt-2"
                xs={"auto"}
                sm={"auto"}
                md={"auto"}
                lg={"auto"}
                xl={"auto"}
              >
                {items[2]}
              </Col>
              <Col
                className="mt-2"
                xs={"auto"}
                sm={"auto"}
                md={"auto"}
                lg={"auto"}
                xl={"auto"}
                style={{ display: "flex", alignItems: "center" }}
              >
                {items[3]}
                {items[4]}
              </Col>
            </Row>
            <Row style={{ marginTop: 20, marginBottom: 20, height: 500 }}>
              {table}
            </Row>
            <Row>
              <S.SizedBox />
            </Row>
          </Col>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <Container>
        <Col>
          <Row className="align-items-center" style={{ marginTop: 20 }}>
            <Col
              className="mt-2"
              xs={"auto"}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
              xl={"auto"}
            >
              {" "}
              {items[0]}
            </Col>
            <Col
              className="mt-2"
              xs={"auto"}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
              xl={"auto"}
            >
              {" "}
              {items[1]}
            </Col>
            <Col
              className="mt-2"
              xs={"auto"}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
              xl={"auto"}
            >
              {" "}
              {items[2]}
            </Col>
            <Col
              className="mt-2"
              xs={"auto"}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
              xl={"auto"}
            >
              {" "}
              {items[3]}
            </Col>
            <Col
              className="mt-2"
              xs={"auto"}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
              xl={"auto"}
              style={{ display: "flex", alignItems: "center" }}
            >
              {" "}
              {items[4]}
              {items[5]}
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20, height: 500 }}>
            {table}
          </Row>
          <Row>
            <S.SizedBox />
          </Row>
        </Col>
      </Container>
    </div>
  );
}

export default ResponsiveRow;
