import styled from "styled-components";
import color from "../../../constants/color";

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all 0.3s;
  place-items: center;
  display: ${(props) => (props.isOpen ? "grid" : "none")};

  &.add {
    z-index: 99;
  }
`;

export const Modal = styled.div`
  background-color: white;
  height: 500px;
  width: 60%;
  border-radius: 10px;
  padding: 32px 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  justify-content: space-evenly;

`;

export const InputField = styled.input`
  width: 100%;
  border: 0;
  border: 1px solid ${color.grey};
  padding: 0 10px;
  font-size: 0.9rem;
  height: 30px;

  background-color: transparent;
  color: ${color.greyShade5};
  font-weight: 500;

  margin-right: ${(props) => props.marginRight ?? "0"};
  transition: 0.5s;

  ::placeholder {
    color: ${color.greyShade5};
    font-weight: 500;
  }
  &:focus {
    background-color: white;
    outline: none;
    border: 1px solid ${color.primary};
  }

  @media only screen and (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const SubHead = styled.div`
  margin-right: 20px;
  text-align: end;
  font-weight: 400;
  min-width: 130px;
  color: ${color.primary};
  font-size: 0.9rem;

  span {
    color: red;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const SubWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const TitleText = styled.h1`
  color: ${color.primary};
`

export const VerifyWrapper = styled.div`
    color: ${color.primary};
    cursor: pointer;
    position: relative;
    bottom: 10px;
    right: 0;
    left: 0;

    &:hover {
      color: grey;
    }
`