const PlanContent = {
  monthly: [
    {
      title: "Silver Plan",
      line1: "DSE (Sales Man) - 1",
      line2: "Retailers - Unlimited",
      line3: "Product/Operator",
      line4: "Unlimited",
      cost: "₹49 only",
      validity: "Valid 28 days",
      planId: 1000000001,
      planType: "month",
    },
    {
      title: "Gold Plan",
      line1: "DSE (Sales Man) - 4",
      line2: "Retailers - Unlimited",
      line3: "Product/Operator",
      line4: "Unlimited",
      cost: "₹149 only",
      validity: "Valid 28 days",
      planId: 1000000002,
      planType: "month",
    },
    {
      title: "Diamond Plan",
      line1: "DSE (Sales Man) - 6",
      line2: "Retailers - Unlimited",
      line3: "Product/Operator",
      line4: "Unlimited",
      cost: "₹199 only",
      validity: "Valid 28 days",
      planId: 1000000003,
      planType: "month",
    },
    {
      title: "Platinum Plan",
      line1: "DSE Unlimited",
      line2: "Retailers - Unlimited",
      line3: "Product/Operator",
      line4: "Unlimited",
      cost: "₹299 only",
      validity: "Valid 28 days",
      planId: 1000000004,
      planType: "month",
    },
  ],

  yearly: [
    {
      title: "Silver Plan",
      line1: "DSE (Sales Man) - 1",
      line2: "Retailers - Unlimited",
      line3: "Product/Operator",
      line4: "Unlimited",
      cost: "₹499 only",
      planId: 1000000001,
      planType: "year",
    },
    {
      title: "Gold Plan",
      line1: "DSE (Sales Man) - 4",
      line2: "Retailers - Unlimited",
      line3: "Product/Operator",
      line4: "Unlimited",
      cost: "₹1499 only",
      planId: 1000000002,
      planType: "year",
    },
    {
      title: "Diamond Plan",
      line1: "DSE (Sales Man) - 6",
      line2: "Retailers - Unlimited",
      line3: "Product/Operator",
      line4: "Unlimited",
      cost: "₹1999 only",
      planId: 1000000003,
      planType: "year",
    },
    {
      title: "Platinum Plan",
      line1: "DSE Unlimited",
      line2: "Retailers - Unlimited",
      line3: "Product/Operator",
      line4: "Unlimited",
      cost: "₹2999 only",
      planId: 1000000004,
      planType: "year",
    },
  ],
};

export default PlanContent;
